.politique-content{
    margin: 60px auto;
    p {
        font-size: 0.85rem;
        text-align: justify;
        margin-top: 10px !important;
    }
    span{
        font-weight: bold;
        font-size: 1rem;
    }
}