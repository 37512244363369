@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700&amp;display=swap");
:root {
  --main-color-one: #2F57EF;
  --main-color-two: #25C6DA;
  --heading-color: #192335;
  --paragraph-color: #192335;
  --body-font: "Inter", sans-serif;
  --heading-font: "Inter", sans-serif;
  --body-font-size: 16px;
  --line-height30: 1.7;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
  font-family: var(--body-font);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

h1 {
  font-size: 75px;
  line-height: 1.2333333333;
}

h2 {
  font-size: 40px;
  line-height: 1.2380952381;
}

h3 {
  font-size: 30px;
  line-height: 1.3833333333;
}

h4 {
  font-size: 24px;
  line-height: 1.2380952381;
}

h5 {
  font-size: 20px;
  line-height: 1.3380952381;
}

h6 {
  font-size: 16px;
  line-height: 1.2380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-weight: 700;
  font-family: var(--heading-font);
}

p {
  color: var(--paragraph-color);
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 10px;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

a:hover {
  color: var(--main-color-one);
}

pre {
  word-break: break-word;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

/*input and button type focus outline disable*/
input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea:focus,
input[type=button]:focus,
input[type=reset]:focus,
input[type=submit]:focus,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

code {
  color: #faa603;
}


.position-relative {
  position: relative;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

dl,
ol,
ul {
  padding-left: 17px;
}

/*---------------------------------------
    ## Button
---------------------------------------*/
.btn {
  height: 55px;
  line-height: 55px;
  padding: 0 30px;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: 600;
  border-radius: 5px;
  z-index: 0;
}
.btn:focus, .btn:active {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn:after {
  content: "";
  background: var(--heading-color);
  position: absolute;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: -1;
  height: 100%;
  left: -35%;
  top: 0;
  -webkit-transform: skew(30deg);
          transform: skew(30deg);
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  width: 0;
}
.btn:hover:after {
  height: 100%;
  width: 135%;
}
.btn i {
  font-size: 14px;
  float: right;
  padding-top: 16px;
  padding-left: 5px;
}
.btn svg {
  font-size: 12px;
  margin-top: 20px;
  margin-left: 6px;
  float: right;
}

.btn-base {
  color: #fff !important;
  background: #0a2fc2 !important;
  padding: 1rem !important;
}

.btn-base svg {
  font-size: 13px;
  margin-top: 22px;
}
.btn-base:hover {
  color: #fff;
}

.btn-black1 {
  background: #000 !important;
  font-weight: 500 !important;
  padding: 20px 60px;
  border-radius: 8px;
  color: #fff !important;
  font-size: 1.2rem;
}

@media screen and (max-width: 440px) {
  .btn-black1{
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 400px) {
  .btn-black1{
    font-size: 0.9rem !important;
    padding: 15px 20px;
  }
}

@media screen and (max-width: 300px) {
  .btn-black1{
    font-size: 0.75rem !important;
    padding: 10px 15px;
  }
}

.btn-black1:hover {
  background: #704810 !important;
  color: #fff !important;
}

/*-----------------------------------------
    ## Preloader Css
-------------------------------------------*/
.spinner {
  margin: 120px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}


/*-----------------------------------------
    ## Breadcumb 
------------------------------------------*/
.breadcrumb-area {
  padding: 100px 0 125px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* margin-top: -120px; */
}
.breadcrumb-area .banner-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-size: 102% 96%;
}
.breadcrumb-area .breadcrumb-inner {
  position: relative;
  z-index: 2;
}
.breadcrumb-area .page-title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.breadcrumb-area .page-list {
  margin: 0;
  padding: 0;
}
.breadcrumb-area .page-list li {
  font-weight: 600;
  list-style: none;
  display: inline-block;
  position: relative;
  /* padding-left: 17px; */
  /* margin-left: 8px; */
  color: var(--heading-color);
}
.breadcrumb-area.bg-cover > div > div > ul > svg{
  font-size: 15px;
  margin: 0 10px;
}

/* .breadcrumb-area .page-list li:after {
  position: absolute;
  left: 0;
  top: 4px;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 13px;
} */
.breadcrumb-area .page-list li:first-child {
  padding-left: 0;
  margin-left: 0;
}
.breadcrumb-area .page-list li:first-child:after {
  display: none;
}

/********* social-media *********/
.social-media {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 2;
}
.social-media li {
  display: inline-block;
  margin: 0 2px;
}
.social-media li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  background: #333F4D;
  color: #fff;
  font-size: 13px;
}
.social-media li a:hover {
  background: var(--main-color-one);
  color: #fff;
}
.social-media li a.facebook {
  color: #3b5999;
  border-color: #3b5999;
}
.social-media li a.twitter {
  color: #55acee;
  border-color: #55acee;
}
.social-media li a.instagram {
  color: #e4405f;
  border-color: #e4405f;
}
.social-media li a.youtube {
  color: #cd201f;
  border-color: #cd201f;
}
.social-media li:first-child {
  margin-left: 0;
}
.social-media li:last-child {
  margin-right: 0;
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/

.bg-gray {
  background: #F3F6FB;
}

.bg-relative {
  position: relative;
}

.bg-cover {
  background-size: cover !important;
  background-position: center;
}

.bg-overlay-yellow {
  position: relative;
  background-size: cover !important;
}
.bg-overlay-yellow:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #0d6efd !important;
  opacity: 0.75;
}
.bg-overlay-yellow .container {
  position: relative;
  z-index: 2;
}
.bg-overlay-yellow .bg-z-index {
  position: relative;
  z-index: 2;
}

.bg-overlay-black {
  position: relative;
  background-size: cover !important;
}
.bg-overlay-black:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #041a3a !important;
  opacity: 0.6;
}
.bg-overlay-black .container {
  position: relative;
  z-index: 2;
}
.bg-overlay-black .bg-z-index {
  position: relative;
  z-index: 2;
}
.box-shadow {
  -webkit-box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
}

.box-shadow-2 {
  -webkit-box-shadow: 0px 16px 52px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 16px 52px 0px rgba(0, 0, 0, 0.05);
}

.pd-top-60 {
  padding-top: 60px;
}

.pd-top-100 {
  padding-top: 100px;
}

.pd-top-110 {
  padding-top: 110px;
}

.pd-top-115 {
  padding-top: 115px;
}

.pd-top-120 {
  padding-top: 120px;
}


.mg-top-120 {
  margin-top: 120px;
}

.mg-bottom-120 {
  margin-bottom: 120px;
}

.pd-bottom-60 {
  padding-bottom: 60px;
}

.pd-bottom-80 {
  padding-bottom: 80px;
}


.pd-bottom-100 {
  padding-bottom: 100px;
}


.pd-bottom-120 {
  padding-bottom: 120px;
}


.position-bottom-left {
  position: absolute;
  left: 30px;
  bottom: 150px;
  width: 50px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-40 {
  border-radius: 40px !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-60 {
  border-radius: 60px;
}

.border-radius-bottom-20 {
  border-radius: 0 0 20px 20px;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}


.box-shadow {
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
          box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
}

.z-index-2 {
  z-index: 2;
}

.fw-500 {
  font-weight: 500;
}

.single-check-inner li {
  list-style: none;
  margin-bottom: 16px;
}
.single-check-inner li img {
  margin-right: 5px;
}
.single-check-inner li i {
  margin-right: 5px;
}
.single-check-inner.style-dot li {
  list-style: inside;
}

.z-index-2 {
  z-index: 2;
}

.overflow-hidden {
  overflow: hidden;
}

.color-light-pink {
  background: #FFE6E2;
}

.color-light-blue {
  background: #E3F8FB;
}

.color-light-yellow {
  background: #FFF9DF;
}

.color-light-purple {
  background: #F5E6FD;
}

.position_animate_img {
  position: absolute;
}

.position-top-right {
  right: 70px;
}

.position-bottom-left {
  left: 70px;
  bottom: 0;
}

/************ animate style ************/
.top_image_bounce {
  -webkit-animation: top-image-bounce 3s infinite ease-in-out;
          animation: top-image-bounce 3s infinite ease-in-out;
}


@keyframes top-image-bounce {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
/*------------------------------------------------
    ## Section title
------------------------------------------------*/
.section-title {
  margin-bottom: 50px;
  position: relative;
}
.section-title .sub-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: 1px;
  position: relative;
  color: var(--main-color-one);
}
.section-title .sub-title.line-after {
  padding-top: 0;
  padding-bottom: 0;
}
.section-title .sub-title.line-after:after {
  content: "";
  position: absolute;
  background: #fff;
  right: -55px;
  top: 8px;
  height: 2px;
  width: 40px;
}
.section-title .sub-title.line-double {
  padding-left: 55px;
  margin-left: -55px;
  padding-top: 0;
  padding-bottom: 0;
}
.section-title .sub-title.line-double:before {
  content: "";
  position: absolute;
  background: #fff;
  left: 0;
  top: 8px;
  height: 2px;
  width: 40px;
}
.section-title .sub-title.line-double:after {
  content: "";
  position: absolute;
  background: #fff;
  right: -55px;
  top: 8px;
  height: 2px;
  width: 40px;
}
.section-title .title {
  margin-bottom: 0;
  margin-top: 0;
  text-transform: capitalize;
}
.section-title .title span {
  color: var(--main-color-one);
}
.section-title .content {
  margin-top: 17px;
  margin-bottom: 0;
}
.section-title .small-title {
  margin-top: 18px;
  font-weight: 600;
  color: var(--heading-color);
}
.section-title .phone {
  font-weight: 400;
  margin-top: 30px;
}
.section-title .phone img {
  margin-right: 6px;
}
.section-title .single-list-wrap {
  margin-top: 21px;
}
.section-title .btn {
  margin-top: 30px;
}
.section-title.style-white .sub-title {
  color: var(--main-color-one);
}
.section-title.style-white .sub-title.left-line:before {
  background: #fff;
}
.section-title.style-white .sub-title.right-line:after {
  background: #fff;
}
.section-title.style-white .sub-title.double-line:before {
  background: #fff;
}
.section-title.style-white .sub-title.double-line:after {
  background: #fff;
}
.section-title.style-white .title {
  color: #fff;
}
.section-title.style-white .content {
  color: #fff;
}
.section-title.style-white .small-title {
  color: #fff;
}


.menu-open {
  opacity: 1;
  margin-right: 0;
  visibility: visible;
}


.responsive-mobile-menu button:focus {
  outline: none;
  border: none;
}

/**************** nav-left-part *************/

.sopen {
  display: block !important;
  
}

.toggle-btn {
  left: auto;
  right: 0;
  position: absolute;
  top: 8px;
  width: 40px;
  height: 40px;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  border: 0;
  background: transparent;
}
.toggle-btn .icon-left {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: var(--heading-color);
  left: 7px;
}
.toggle-btn .icon-left:before {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: -7px;
  left: 0;
}
.toggle-btn .icon-left:after {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: 7px;
  left: 0;
}
.toggle-btn .icon-left:hover {
  cursor: pointer;
}
.toggle-btn .icon-right {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: var(--heading-color);
  left: 18px;
}
.toggle-btn .icon-right:before {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: -7px;
  left: 0;
}
.toggle-btn .icon-right:after {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: 7px;
  left: 0;
}
.toggle-btn .icon-right:hover {
  cursor: pointer;
}
.toggle-btn.open .icon-left {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  background: transparent;
}
.toggle-btn.open .icon-left:before {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
          transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
}
.toggle-btn.open .icon-left:after {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
          transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
}
.toggle-btn.open .icon-right {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  background: transparent;
}
.toggle-btn.open .icon-right:before {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
          transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
}
.toggle-btn.open .icon-right:after {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
          transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
}
.toggle-btn:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .details {
    padding-left: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-area .logo {
    padding-top: 0px !important;
  }
  .widget ul {
    text-align: left;
  }
  .navbar-collapse {
    background: #fff;
    margin-top: 0px;
    width: 100%;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    right: 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav {
    margin-bottom: 20px;
  }
  .navbar-area {
    padding-bottom: 0px;
  }
  .footer-area .copyright-area-inner {
    padding: 20px;
  }
  .navbar-expand-lg .navbar-collapse {
    margin-top: 0px;
  }
  .details {
    padding-left: 25px;
  }
  .footer-area .footer-top .widget.widget_nav_menu ul li a {
    font-size: 14px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    padding: 0 0 0 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    padding: 12px 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:last-child a {
    padding-bottom: 3px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover i {
    opacity: 0;
    margin-right: -18px;
  }
  .nav-right-part-mobile {
    display: block;
  }
  .nav-right-part-desktop {
    display: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover {
    background: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    color: #333333;
  }
}
/* Tablet Layout wide: 767px. */
@media only screen and (max-width: 767px) {

  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }
  .responsive-mobile-menu .navbar-toggler {
    position: absolute;
    left: calc(100% - 130px);
    top: 10px;
  }
  .navbar-area .logo {
    padding-top: 0px !important;
  }
}
/* medium tablet layout 599px */
@media only screen and (max-width: 575px) {
  .navbar-area .nav-container {
    margin: 0px 0px;
  }
  .navbar-area .logo {
    padding-top: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .responsive-mobile-menu .navbar-toggler {
    left: calc(100% - 95px);
  }
}
/*----------------------------------------------
    # banner style
----------------------------------------------*/
.banner-area-1 {
  padding: 0px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
}
.banner-area-1 .banner-1-top {
  padding-bottom: 85px;
}
.banner-area-1 h2 {
  font-size: 75px;
}
.banner-area-1 h2 span {
  color: var(--main-color-one);
}
.banner-area-1 .b-content {
  font-size: 20px;
  margin-bottom: 15px;
}
.banner-area-1 .b-left-count h3 {
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1;
}
.banner-area-1 .b-left-count p {
  color: #7C7D93;
  margin-bottom: 7px;
  font-size: 14px;
}
.banner-area-1 .main-thumb {
  position: relative;
}
.banner-area-1 .main-thumb .icon-img {
  position: absolute;
  right: 60px;
  top: -40px;
}
.banner-area-1 .banner-count-area p {
  color: #7C7D93;
  font-size: 15px;
}
.banner-area-1 .banner-count-area .btn {
  height: 40px;
  line-height: 40px;
}
.banner-area-1 .banner-count-area .banner-count {
  position: relative;
  display: inline-block;
}
.banner-area-1 .banner-count-area .banner-count p {
  color: #7C7D93;
}
.banner-area-1 .banner-count-area .banner-count canvas {
  height: 115px;
  width: 115px;
  background: radial-gradient(50% 50% at 50.02% 50.01%, rgba(51, 231, 210, 0.4) 0%, #FFF 98%);
}
.banner-area-1 .banner-count-area .banner-count span.chart_counter {
  font-size: 30px;
  color: #233A45;
  position: absolute;
  top: 35px;
  left: 2px;
  right: 0;
  text-align: center;
  width: 90px;
}
.banner-area-1 .banner-count-area .banner-count span.chart_counter:after {
  content: "%";
  position: absolute;
  right: 0;
  top: 0;
  color: #233A45;
  font-size: 30px;
}

.banner-area-2 {
  padding: 190px 0;
}
.banner-area-2 h6 {
  color: #4ADA85;
}
.banner-area-2 .banner-shape {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}

/*----------------------------------------------
    # about style
----------------------------------------------*/
.about-thumb-inner {
  position: relative;
}

.about-thumb-inner .main-img {
  position: relative;
  z-index: 1;
}

/*----------------------------------------------
    # content style
----------------------------------------------*/

.border-radius-30 {
  border-radius: 30px;
}

.fw-400 {
  font-weight: 400;
}

.future-generations-inner {
  border-radius: 5px;
}
.future-generations-inner .left-content {
  padding: 30px;
}

.offer-inner h2 {
  font-size: 60px;
}
.offer-inner p {
  font-size: 18px;
}

/************ single-inpur-inner ************/
.single-input-inner {
  margin-bottom: 20px;
}
.single-input-inner input {
  width: 100%;
  height: 52px;
  border: 0 !important;
  padding: 0 18px;
  border-radius: 6px;
  font-weight: 300;
}
.single-input-inner input::-webkit-input-placeholder {
  color: #adadad;
}
.single-input-inner input::-moz-placeholder {
  color: #adadad;
}
.single-input-inner input:-ms-input-placeholder {
  color: #adadad;
}
.single-input-inner input::-ms-input-placeholder {
  color: #adadad;
}
.single-input-inner input::placeholder {
  color: #adadad;
}
.single-input-inner textarea {
  width: 100%;
  border: 0 !important;
  height: 140px;
  border-radius: 6px;
  padding: 14px 18px;
  font-weight: 300;
}
.single-input-inner textarea::-webkit-input-placeholder {
  color: #adadad;
}
.single-input-inner textarea::-moz-placeholder {
  color: #adadad;
}
.single-input-inner textarea:-ms-input-placeholder {
  color: #adadad;
}
.single-input-inner textarea::-ms-input-placeholder {
  color: #adadad;
}
.single-input-inner textarea::placeholder {
  color: #adadad;
}
.single-input-inner.style-bg input {
  background: #F3F6FB;
}
.single-input-inner.style-bg input::-webkit-input-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg input::-moz-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg input:-ms-input-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg input::-ms-input-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg input::placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg textarea {
  background: #F3F6FB;
}
.single-input-inner.style-bg textarea::-webkit-input-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg textarea::-moz-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg textarea:-ms-input-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg textarea::-ms-input-placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg textarea::placeholder {
  color: #3F517E;
}
.single-input-inner.style-bg .single-select {
  background: #F3F6FB;
  color: #3F517E;
}
/*---------------------------------------------------
	widget styles
----------------------------------------------------*/
.widget-title {
  position: relative;
  font-size: 20px;
}

.td-sidebar .widget {
  margin-bottom: 34px;
  padding: 30px;
  border-radius: 6px;
  border: 1px solid #ededed;
}
.td-sidebar .widget .widget-title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 8px;
  color: var(--main-color-one);
}
.td-sidebar .widget .widget-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 3px;
  width: 100%;
  background: #E1E1E1;
}
.td-sidebar .widget .widget-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 3px;
  width: 60px;
  background: var(--main-color-one);
  z-index: 2;
}
.td-sidebar .widget.widget-recent-post ul li .media {
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
          box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
  padding: 15px 20px;
  border-radius: 5px;
}

.footer-area .widget-title {
  color: #000;
}

.widget-recent-post ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 9px;
  padding-bottom: 0;
}

.widget-recent-post ul li {
  margin-bottom: 15px;
}

.widget-recent-post ul li .media {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.widget-recent-post ul li .media .media-left {
  margin-right: 18px;
}

.widget-recent-post ul li .media .media-left img {
  border-radius: 4px;
}

.widget-recent-post ul li .media .media-body .title {
  margin-bottom: 8px;
}

.widget-recent-post ul li .media .media-body .post-info {
  font-size: 13px;
  color: var(--heading-color);
}

.widget-recent-post ul li .media .media-body .post-info span {
  color: #3F517E;
}

.widget-recent-post ul li .media .media-body .post-info svg,
.widget-recent-post ul li .media .media-body .post-info i {
  margin-right: 8px;
  color: var(--main-color-one);
}

.widget-recent-post ul li:last-child {
  border-bottom: 0 !important;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget_catagory ul {
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: -5px;
}

.widget_catagory ul li {
  list-style: none;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding-bottom: 10px;
}
.widget_catagory ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget_catagory ul li a {
  position: relative;
  padding: 10px 15px;
  background: #F8F9FC;
  display: block;
  padding-right: 60px;
  border-radius: 6px;
}
.widget_catagory ul li a span {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid rgba(66, 74, 97, 0.1);
  display: inline-block;
  padding: 10px 18px;
  right: 0;
  border-radius: 6px;
}

.widget_catagory ul li:last-child {
  margin-bottom: 0;
}

/******** widget-contact *********/
.widget_contact .details {
  padding: 0;
}
.widget_contact .details li {
  margin-bottom: 10px;
  position: relative;
  list-style: none;
}
.widget_contact .details li i {
  margin-right: 14px;
  color: var(--main-color-one);
}
.widget_contact .details li svg {
  margin-right: 14px;
  color: var(--main-color-one);
}
.widget_contact .details li:last-child {
  margin-bottom: 0;
}
.widget_contact .details li .time {
  font-size: 12px;
  margin-top: 3px;
}
.widget_contact .social-media li a {
  border: 1px solid var(--paragraph-color);
  background: transparent;
  line-height: 28px;
}
.widget_contact .social-media li a svg {
  margin-right: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.widget_contact .social-media li a:hover {
  background: var(--main-color-one);
  border: 1px solid var(--main-color-one);
}
.widget_contact .social-media li a:hover svg {
  color: #fff !important;
}

.course-sidebar .widget {
  border: 0;
  padding: 0;
}
.course-sidebar .widget .widget-title {
  padding-bottom: 0;
}
.course-sidebar .widget .widget-title svg {
  margin-top: 6px;
  float: left;
  margin-right: 10px;
  font-size: 15px;
}
.course-sidebar .widget .widget-title:before, .course-sidebar .widget .widget-title:after {
  display: none;
}
.course-sidebar .widget_catagory .catagory-items li {
  border: 1px solid #E1E1E1;
  padding-bottom: 0;
  margin-bottom: 10px;
  border-radius: 5px;
}
.course-sidebar .widget_catagory .catagory-items li a {
  background-color: transparent;
  padding: 15px;
  color: var(--heading-color);
}
.course-sidebar .widget_catagory .catagory-items li a span {
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #E1E1E1;
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
  color: var(--main-color-one);
  position: relative;
  top: auto;
  padding: 0;
  margin-right: 5px;
}
.course-sidebar .widget_catagory .catagory-items li a:hover {
  background: var(--main-color-one);
  color: #fff;
}
.course-sidebar .widget_catagory .catagory-items li a:hover span {
  color: #fff;
}
.course-sidebar .widget_download ul {
  padding: 8px 0;
  background: #fff;
  border-radius: 5px;
}
.course-sidebar .widget_download ul li {
  list-style: none;
}
.course-sidebar .widget_download ul li a {
  padding: 12px 15px;
  display: block;
  border: 1px solid #E1E1E1;
  margin-bottom: 10px;
  border-radius: 5px;
}
.course-sidebar .widget_download ul li a svg {
  height: 30px;
  width: 30px;
  background: rgba(36, 107, 253, 0.1);
  color: var(--main-color-one);
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  padding: 8px;
  float: right;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.course-sidebar .widget_download ul li a:hover svg {
  background: var(--main-color-one);
  color: #fff;
}

/*------------------------------------------------
    blog style
------------------------------------------------*/
.single-blog-inner {
  margin-bottom: 50px;
  overflow: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
          box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
}
.single-blog-inner .details {
  padding: 30px;
}
.single-blog-inner .details h2 {
  margin-bottom: 3px;
}
.single-blog-inner .details h2:hover a {
  color: var(--main-color-one);
}
.single-blog-inner .details .title {
  margin-bottom: 17px;
}
.single-blog-inner .details p {
  margin-bottom: 16px;
}

/******** blog-details-page-content ***********/
.blog-details-page-content .single-blog-inner {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

/*--------------------------------------------------
    ##Footer style
---------------------------------------------------*/
.footer-area {
  position: relative;
  padding-top: 40px !important;
}
.footer-area:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: var(--heading-color);
  z-index: -1;
}
.footer-area .widget {
  position: relative;
  z-index: 2;
  margin-bottom: 70px;
}
.footer-area .widget-title {
  margin-bottom: 30px;
  position: relative;
}
.footer-area .widget_about .thumb {
  margin-bottom: 20px;
}
.footer-area .widget_about .thumb img {
  max-width: 150px;
}
.footer-area .widget_about .details p {
  margin-bottom: 0;
  color: #fff;
}
.footer-area .widget_about .details p svg {
  color: var(--main-color-one);
  margin-right: 10px;
}
.footer-area .widget_about .details p + p {
  font-weight: 500;
}
.footer-area .widget_about .details .social-media {
  margin-top: 26px;
}
.footer-area .widget_about .details .social-media li a {
  border: 1px solid #CDD8F9;
  background: transparent;
  color: var(--heading-color);
  border-radius: 50%;
  font-size: 14px;
}
.footer-area .widget_about .details .social-media li a:hover {
  color: #fff;
  background: var(--main-color-one);
  border: 1px solid var(--main-color-one);
}
.footer-area .widget_about .details .social-media li a:hover svg path {
  color: #fff;
}
.footer-area .widget_about .subscribe {
  position: relative;
}
.footer-area .widget_about .subscribe input {
  width: 100%;
  height: 44px;
  padding: 0 50px 0 15px;
  border-radius: 5px;
  border: 1px solid #E3E3E3;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}
.footer-area .widget_about .subscribe button {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  height: 44px;
  width: 40px;
  border: 0;
  background: var(--main-color-one);
  color: #fff;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.footer-area .widget_about .subscribe button:hover {
  background: var(--heading-color);
}
.footer-area .widget_nav_menu ul {
  margin: 0;
  padding: 0;
}
.footer-area .widget_nav_menu ul li {
  list-style: none;
  margin-bottom: 7px;
  position: relative;
  color: #fff;
  position: relative;
}
.footer-area .widget_nav_menu ul li svg {
  margin-right: 8px;
  color: #333F4D;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.footer-area .widget_nav_menu ul li:hover svg {
  color: var(--main-color-one);
}
.footer-area .widget_subscribe .single-input-inner {
  position: relative;
}
.footer-area .widget_subscribe .single-input-inner input {
  padding-right: 40px;
}
.footer-area .widget_subscribe button {
  padding: 0;
  border: 0;
  position: absolute;
  right: 15px;
  top: 10px;
  background: transparent;
  color: var(--main-color-one);
}
.footer-area .widget_subscribe label {
  color: #fff;
  position: relative;
  padding-left: 22px;
}
.footer-area .widget_subscribe label input {
  position: absolute;
  left: 0;
  top: 7px;
}
.footer-area .widget-recent-post ul li .media .media-body .title {
  color: #fff;
}
.footer-area .widget-recent-post ul li .media .media-body .post-info span {
  color: #fff;
}

.footer-top {
  padding-top: 55px;
  padding-bottom: 90px;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding: 23px 0;
  position: relative;
  z-index: 2;
}
.footer-bottom p {
  margin-bottom: 0;
  color: #fff;
}
.footer-bottom a {
  margin-right: 10px;
  color: #fff;
}
.footer-bottom a:last-child {
  margin-right: 0;
}