.checkoutpage{
    padding: 60px 80px;
    width: 100%;
    display: flex;
    gap: 20px;
    .delv-paiment{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .card{
            border-width: 2px;
        }
    }
}

@media screen and (max-width: 768px){
    .checkoutpage{
        flex-direction: column;
        .delv-paiment{
            width: 100%;
        }
    }
}


@media screen and (max-width: 520px){
    .checkoutpage{
        padding: 40px 10px;
    }
}