svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-16 {
    width: 1em;
}
.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}

.contact-ph-ml{
    display: flex;
    flex-direction: column;
    gap: 10px;
    div {
        display: flex;
        gap: 6px;
        align-items: center;
        svg{
            font-size: 30px;
            padding: 6px;
            border: 1.5px solid #CDD8F9;
            border-radius: 100%;
        }
    }
}

.theadresse{
    span{
        font-size: 0.8rem;
    }
}

.privacycondi{
    li{
      cursor: pointer;  
      width: fit-content;
    }
    li:hover{
        color: #3457e1 !important;
    }
}


.logopayzone{
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    margin-top: 10px;
    span{
        color: green;
        font-weight: 600;
    }
}