/* style.scss */
.true-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  background-color: #03032d;
    .price-tag {
      background-color: rgb(0 47 88);
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 18px;
      font-weight: bold;
    }
  section.image-section img {
    max-width: 1000px;
    text-align: center;
 } 
 
}

.image-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  img {
    max-width: 700px;
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    border-radius: 8px;
  }
}

.buy-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #ff0000;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  margin: 20px 0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #bf2525;
    color: white;
  }

  svg {
    margin-right: 8px;
  }
}

@media screen and (max-width: 768px) {
  .buy-button{
    width: 80%;
  }
}

.image-gallery {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;

  .large-screen {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      width: 175px;
      height: auto;
      margin: 5px;
      border-radius: 8px;
    }
  }

  .small-screen2 {
    display: none;

    .carousel-inner {
      border-radius: 8px;
    }
  }

  @media (max-width: 768px) {
    .large-screen {
      display: none;
    }

    .small-screen2 {
      display: block;
    }
  }
}