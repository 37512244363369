.mega-menu .sub-menu {
	width: 100%;
	padding: 20px !important;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav > li.menu-item-has-children:before,
.navbar-area .nav-container .navbar-collapse .navbar-nav > li.menu-item-has-children:after {
	display: none;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav > li.menu-item-has-children {
    padding-left: 3px !important;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav > li.menu-item-has-children.mega-menu {
    position: initial;
}
@media all and (min-width: 1600px) {
	.container {
		max-width: 1280px;
	}
	.banner-area-1 h2 {
	    font-size: 50px;
	}
	.navbar-area .nav-container .logo {
	    margin-right: 60px;
	}
	.navbar-area .navbar-shape {
	    width: 370px;
	    height: 115px;
	}
}
@media all and (max-width: 1599px) {
	.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
	    font-size: 14px;
	}
	.banner-area-1 h2 {
	    font-size: 50px;
	}
	.banner-area-2 {
	    padding: 110px 0 90px;
	}
	.banner-area-2 .banner-shape {
	    width: 50%;
	}
	.single-work-process-inner {
	    padding: 30px 20px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-area .nav-container .navbar-collapse .navbar-nav > li {
	    margin-right: 0;
	}
	.footer-subscribe .footer-subscribe-inner h2 {
		font-size: 30px;
	}
	.future-generations-inner .details p {
		font-size: 14px;
	}
}
@media all and (max-width: 1199px) {
	.banner-area-1 h2 {
	    font-size: 50px;
	}
	.banner-area-2 h2 {
	    font-size: 40px;
	}
	.offer-inner h2 {
	    font-size: 50px;
	}
}
@media all and (max-width: 991px) {
	.navbar-area .nav-container .navbar-collapse .navbar-nav {
	    padding-left: 0;
	}
	.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
	    padding-right: 0;
	}
	.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:after {
	    top: 26px;
	}
	.navbar-area .nav-container .navbar-collapse {
	    padding: 13px;
	    padding-left: 0;
	}
	.navbar-area .nav-container .navbar-collapse .navbar-nav {
	    margin: 0;
	}
	.navbar-area:after {
	    display: none;
	}
	.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
	    font-size: 16px;
	}
	.navbar-area .nav-container.navbar-bg {
	    padding-left: 15px;
	}
	.navbar-area {
	    margin-top: 0;
	    padding-top: 0;
	}
	.slider-control-right-top .owl-nav {
	    display: none;
	}
	.footer-bottom a {
		font-size: 15px;
	}
	.banner-area-2 .banner-shape {
	    display: none;
	}
	.td-sidebar {
		margin-top: 100px;
	}
	.offer-inner {
		padding: 90px 30px;
	}
	.navbar-area .navbar-shape {
	    display: none;
	}
}
@media all and (max-width: 767px) {
	.breadcrumb-area .page-title {
	    font-size: 50px;
	}
}
@media all and (max-width: 575px) {

	.navbar-top .topbar-right li {
		margin-bottom: 4px;
	}
	.breadcrumb-area .page-title {
	    font-size: 30px;
	}
	.breadcrumb-area .page-list li {
		font-size: 15px;
	}
	.subscribe-area h2 {
		font-size: 24px;
	}
	.banner-area-1 h2 {
	    font-size: 30px;
	}
	.section-title .title {
		font-size: 30px;
	}
	.footer-subscribe .footer-subscribe-inner {
		padding: 25px;
	}
	.footer-subscribe .footer-subscribe-inner.style-3 input {
	    padding: 0 20px;
	    height: 55px;
	}
	.footer-subscribe .footer-subscribe-inner.style-3 .btn {
		position: relative;
	    right: 0;
	    width: 100%;
	}
	.navbar-area .navbar-shape {
	    height: 125%;
	}
	.blog-details-page-content .single-blog-inner .details h2 {
		font-size: 28px;
	}
	.single-blog-inner .details .title {
		font-size: 26px;
	}
	.offer-inner h2 {
		font-size: 28px;
	}
	.offer-inner p {
		font-size: 16px;
	}
	.position_animate_img.position-top-right {
	    display: none;
	}
	.position_animate_img.position-middle-right {
	    display: none;
	}
	.position_animate_img.position-bottom-right {
	    display: none;
	}
	.position_animate_img.position-bottom-left {
	    display: none;
	}
	.navbar-area .nav-container {
	    margin: 0px 0px;
	    padding: 5px 0 5px 10px;
	}
}
@media all and (max-width: 360px) {
	
}


@media all and (min-width: 1300px) {
	.custom-container {
		max-width: 1250px;
	}
}
@media all and (min-width: 2000px) {
	.contact-inner-2 {
	    max-width: 1920px;
	    margin: 0 auto;
	}
}
@media all and (min-width: 1800px) {
	.custom-container {
		max-width: 1720px;
	}
}