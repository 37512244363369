@font-face {
    font-family: 'CustomFont';
    src: url('../../assets/fonts/Montserrat-Medium.ttf') format('woff2');
}

*{
    font-family: CustomFont, sans-serif;
}
.main-page{
    display: flex;
    flex-direction: column;
    gap: 50px;
}