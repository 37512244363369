.banner-contentarea{
    width: 100%;
    text-align: center;
}
.subtitles-parts{
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 20px;
    span{
        font-size: 4rem;
        text-transform: uppercase;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        margin-bottom: -10px;
    }
    span:first-of-type{
        color: #3457e1;
    }
    span:nth-child(2){
        color: white;
        font-size: 4.5rem;
    }
    span:nth-child(3){
        color: white;
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 768px) {
    .subtitles-parts span {
        font-size: 3rem !important; /* Adjust font size for smaller screens */
    }
}

@media screen and (max-width: 360px) {
    .subtitles-parts span {
        font-size: 2rem !important; /* Adjust font size for smaller screens */
    }
}

.description-banner{
    max-width: 800px;
    margin: auto;
}