.paiment-card {
    .card{
        border-width: 2px;
        .card-body{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    .paiment-title {
        display: flex;
        justify-content: space-between;
        margin-left: 2px;
        padding-bottom: 15px;
        border-bottom: 2px solid rgb(223 223 223);
        span{
            font-size: 1.1rem;
            font-weight: 600;
        }
    }

    .form-group{
        margin-bottom: 10px;
        label{
            font-size: 0.85rem;
            margin-bottom: 0.5rem;
        }
    }

    .submitbtn{
        margin-top: 10px;
        font-size: 0.85rem;
    }
}