.delivery-card {
    .card{
        border-width: 2px;
        .card-body{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    .delivery-title {
        display: flex;
        justify-content: space-between;
        margin-left: 2px;
        padding-bottom: 15px;
        border-bottom: 2px solid rgb(223 223 223);
        span{
            font-size: 1.1rem;
            font-weight: 600;
        }
    }

    .delivery-form{
        form{
            .form-groupe{
                margin-bottom: 10px;
                label {
                    font-size: 0.85rem;
                }
            }
        }
    }
}
.password-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  
    .form-control {
      padding-right: 40px; // Ajustez cette valeur en fonction de la taille de l'icône
    }
  
    .password-icon {
      position: absolute;
      right: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
  
      svg {
        width: 20px; // Ajustez cette valeur en fonction de la taille souhaitée
        height: 20px;
        color: #6c757d; // Couleur de l'icône
      }
    }
  }
  