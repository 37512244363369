.icon-texto{
    display: flex;
    align-items: center;
    gap: 10px;

    img{
        width: 60px;
    }
}

.wrapper-cont-data{
    display: flex;
    flex-direction: column;
    gap: 8px;   
    max-height: max-content; 
    .video-content{
    display: flex;
    gap: 8px;
    svg{
        color: rgb(212, 38, 38);
    }
}
}