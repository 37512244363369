.success-page{
      text-align: center;
      padding: 40px 0;
    h1 {
        color: #88B04B;
        font-weight: 900;
        font-size: 28px;
        margin: 10px 0;
      }
      h1.crossmark-title{
        color: #e42416;
      }
      p {
        color: #404F5E;
        font-size:16px;
        margin: 0;
      }
    i {
      color: #9ABC66;
      font-size: 100px;
      line-height: 200px;
      margin-left:-15px;
    }

    i.crossmark {
      color: #e42416;
      font-size: 100px;
      line-height: 200px;
      margin-left:-15px;
    }
    .card {
      background: white;
      padding: 60px;
      border-radius: 4px;
      box-shadow: 0 2px 3px #C8D0D8;
      display: inline-block;
      margin: 0 auto;
      max-width: 550px;
    }
}


/* Loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white */
  z-index: 9999;
}

.spinner {
  border: 16px solid #ccc; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
