@font-face {
    font-family: 'CustomFont';
    src: url('../../assets/fonts/Montserrat-Medium.ttf') format('woff2');
}

.shopping-card {
    width: 50%;
    font-family: CustomFont, sans-serif;
    .card{
        border-width: 2px;
        .card-body{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
    .shop-title {
        display: flex;
        justify-content: space-between;
        margin-left: 2px;
        padding-bottom: 15px;
        border-bottom: 2px solid rgb(223 223 223);
        span{
            font-size: 1.1rem;
            font-weight: 600;
        }
    }

        .showlistshop {
            border-bottom: 2px solid rgb(223 223 223);
            .eachcourse {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                img {
                    min-width: 70px;
                    max-width: 100px;
                    height: auto;
                    margin-right: 20px;
                    border-radius: 8px;
                }
                .description-part {
                    flex: 1;
                    span {
                        display: block;
                        margin-bottom: 5px;
                        &:first-child {
                            font-weight: bold;
                            font-size: .95rem; // Adjust as needed
                        }
        
                        &:nth-child(2) {
                            color: #666; // Adjust as needed
                            font-size: 0.8rem;
                        }
                    }
                }
        
                .price-part {
                    font-weight: bold;
                    font-size: 1rem;
                    margin-right: 10px;
                }
            }
        }
        

    .sub-delivery {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 2px solid rgb(223 223 223);
        span {
            display: block;
            margin-bottom: 5px;
            &:first-child {
                font-size: .95rem; // Adjust as needed
            }

            &:nth-child(2) {
                color: #666; // Adjust as needed
                font-size: 0.85rem;
                margin-right: 10px;
            }
        }
    }

    .total {
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
        span {
            display: block;
            margin-bottom: 5px;
            &:first-child {
                font-size: .95rem; // Adjust as needed
            }

            &:nth-child(2) {
                color: #666; // Adjust as needed
                font-size: 0.85rem;
            }
        }
    }
}
@media screen and (max-width: 768px){
    .shopping-card{
        width: 100%;
    }
}